import React from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { Box, Button, Stack } from '@tymate/margaret';
import { Container, Title, ErrorMessage } from 'ui';
import { TextField, TextareaField } from 'components/Forms';
import { MdMail } from 'react-icons/md';
import { ERRORS } from 'utils';
import * as Yup from 'yup';

const Fields = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(2)};
  width: 100%;

  ${({ theme }) => theme.media.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

const Wrapper = styled(Box).attrs({ marginBottom: 8 })`
  background-color: #ffffff;
  padding: ${({ theme }) => theme.spacing()};
  position: relative;
  z-index: 1;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.07), 0 4px 8px 0 rgba(0, 0, 0, 0.07);

  ${({ theme }) => theme.media.tablet`
    padding: ${({ theme }) => theme.spacing(2)};
  `}
`;

const ContactForm = ({ onSubmit }) => (
  <Container size="narrow">
    <Wrapper>
      <Stack direction="column" gutterSize={2} size="full" alignX="center">
        <Title variant="secondary">
          <Stack direction="row" gutterSize={0.5} alignY="center">
            <Stack alignY="center">
              <MdMail />
            </Stack>
            <strong>Contact</strong>
          </Stack>
        </Title>

        <Formik
          initialValues={{
            name: '',
            email: '',
            phone: '',
            message: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email(ERRORS.EMAIL).required(ERRORS.REQUIRED),
            name: Yup.string().required(ERRORS.REQUIRED),
            phone: Yup.string().required(ERRORS.REQUIRED),
            message: Yup.string().required(ERRORS.REQUIRED),
          })}
          onSubmit={onSubmit}
        >
          {({ status }) => (
            <Form style={{ width: '100%' }}>
              <Stack direction="column" gutterSize={2} size="full">
                <Fields>
                  <TextField label="Nom" name="name" gridColumn="1 / -1" />
                  <TextField type="email" label="Email" name="email" />
                  <TextField
                    inputmode="numeric"
                    pattern="[0-9]*"
                    label="Numéro de téléphone"
                    name="phone"
                  />
                  <TextareaField
                    name="message"
                    label="Message"
                    gridColumn="1 / -1"
                    rows={4}
                  />
                </Fields>
                {Boolean(status) && <ErrorMessage>{status}</ErrorMessage>}
                <Stack size="full" alignX="center">
                  <Button variant="primary">Envoyer</Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Wrapper>
  </Container>
);

export default ContactForm;
