import React from 'react';
import { graphql } from 'gatsby';
import axios from 'axios';
import qs from 'query-string';
import { SEO, Layout, Hero } from 'components';
import { MainTitle, Subtitle } from 'ui';
import ContactForm from 'components/ContactForm';
import { GATSBY_ZAPIER_URL_CONTACT } from '../constants';
import toast from 'react-hot-toast';

const Expertises = ({ data: { datoCmsContactPage } }) => {
  const handleSubmitContactForm = async (values, { setStatus, resetForm }) => {
    try {
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({
          ...values,
          contactKind: values?.contactKind?.label,
        }),
        url: GATSBY_ZAPIER_URL_CONTACT,
      };
      await axios(options);
      toast.success('Votre demande a bien été transmise.');
      resetForm();
    } catch (err) {
      setStatus('Une erreur est survenue, merci de réessayer ultérieurement');
    }
  };

  return (
    <>
      <SEO
        title={datoCmsContactPage?.metaTags?.title}
        description={datoCmsContactPage?.metaTags?.description}
      />

      <Layout
        hero={
          <Hero cover={datoCmsContactPage?.cover} variant="bleeding">
            <MainTitle as="h1" variant="light">
              {datoCmsContactPage?.title}
            </MainTitle>
            {Boolean(datoCmsContactPage?.description) && (
              <Subtitle variant="light">
                {datoCmsContactPage?.description}
              </Subtitle>
            )}
          </Hero>
        }
      >
        <ContactForm onSubmit={handleSubmitContactForm} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query contact {
    datoCmsContactPage {
      title
      metaTags {
        title
        description
      }
      cover {
        gatsbyImageData(aspectRatio: 1.77, layout: FULL_WIDTH)
      }
    }
  }
`;

export default Expertises;
