import React, { useState } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { Stack } from '@tymate/margaret';
import { FormField, Label, Input, ErrorMessageWrapper } from 'ui';
import { uniqueId } from 'lodash';

// https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
const GrowWrap = styled.div`
  display: grid;
  overflow: hidden;
  width: 100%;
  min-height: 64px;
  &:after {
    content: attr(data-replicated-value) ' ';
    white-space: pre-wrap;
    visibility: hidden;
  }
  > textarea {
    resize: none;
  }
  > textarea,
  &:after {
    width: 100%;
    max-height: ${({ maxHeight }) => maxHeight || '200px'};
    overflow: auto;
    box-shadow: ${({ theme }) => theme.boxShadows.subtle};
    border: 1px solid ${({ theme }) => theme.separator};
    padding: 0.5rem;
    font: inherit;
    grid-area: 1 / 1 / 2 / 2;
  }
`;

const TextareaField = ({
  name,
  label,
  rightLabel,
  maxHeight,
  gridColumn,
  ...props
}) => {
  const [field, { touched, error }] = useField({ name });
  const [id] = useState(uniqueId());

  const hasError = Boolean(touched) && Boolean(error);

  return (
    <FormField gridColumn={gridColumn}>
      <Stack alignX="space-between" alignY="center" marginBottom={0.5}>
        {label && <Label htmlFor={id}>{label}</Label>}
        {rightLabel}
      </Stack>

      <Stack style={{ position: 'relative' }}>
        <GrowWrap data-replicated-value={field.value} maxHeight={maxHeight}>
          <Input as="textarea" {...field} {...props} id={id} />
        </GrowWrap>
      </Stack>
      {hasError ? <ErrorMessageWrapper>{error}</ErrorMessageWrapper> : null}
    </FormField>
  );
};

export default TextareaField;
