import React, { useRef } from 'react';
import { useField } from 'formik';
import {
  Stack,
  Dropdown,
  PopoverMenu,
  PopoverItem,
  PopoverItemButton,
} from '@tymate/margaret';
import { FormField, Label, Input } from 'ui';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useDeepCompareEffect } from 'react-use';
import Dotdotdot from 'react-dotdotdot';

const SelectField = ({
  name,
  label,
  rightLabel,
  maxHeight,
  gridColumn,
  options = [],
  ...props
}) => {
  const [{ value }, , { setValue, setTouched }] = useField({ name });
  const dropdownRef = useRef();

  const handleSelectValue = option => {
    setValue(option);
    setTouched(true);
    if (Boolean(dropdownRef.current)) {
      dropdownRef.current.close();
    }
  };

  useDeepCompareEffect(() => {
    if (Boolean(dropdownRef.current)) {
      dropdownRef.current.close();
    }
  }, [{ value }]);

  return (
    <FormField gridColumn={gridColumn}>
      <Stack alignX="space-between" alignY="center" marginBottom={0.5}>
        {label && <Label>{label}</Label>}
        {rightLabel}
      </Stack>

      <Stack alignX="stretch" size="full" css={{ '> *': { width: '100%' } }}>
        <Dropdown
          shouldCloseDropdownOnTriggerClick
          ref={dropdownRef}
          trigger={
            <Input as="div" {...props}>
              <Stack
                size="full"
                alignX="space-between"
                gutterSize={1}
                alignY="center"
              >
                <Dotdotdot clamp={1}>{value?.label}</Dotdotdot>
                <Stack>
                  <MdKeyboardArrowDown size={20} />
                </Stack>
              </Stack>
            </Input>
          }
        >
          <PopoverMenu>
            {options.map(option => (
              <PopoverItem key={option.value}>
                <PopoverItemButton
                  type="button"
                  onClick={() => handleSelectValue(option)}
                >
                  {option.label}
                </PopoverItemButton>
              </PopoverItem>
            ))}
          </PopoverMenu>
        </Dropdown>
      </Stack>
    </FormField>
  );
};

export default SelectField;
