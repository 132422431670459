import React, { useState } from 'react';
import { useField } from 'formik';
import { FormField, Label, Input, ErrorMessageWrapper } from 'ui';
import { Stack } from '@tymate/margaret';
import { uniqueId } from 'lodash';

const TextField = ({ label, name, rightLabel, gridColumn, ...props }) => {
  const [field, { touched, error }] = useField({ name });
  const [id] = useState(uniqueId());

  const hasError = Boolean(touched) && Boolean(error);

  return (
    <FormField gridColumn={gridColumn}>
      <Stack alignX="space-between" alignY="center" marginBottom={0.5}>
        {label && <Label htmlFor={id}>{label}</Label>}
        {rightLabel}
      </Stack>

      <Input {...field} {...props} id={id} />
      {hasError ? <ErrorMessageWrapper>{error}</ErrorMessageWrapper> : null}
    </FormField>
  );
};

export default TextField;
